import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { keyframes } from 'styled-components';
import pilotbox from './pilotbox.jpg';
import logo from './storklogo.png'

// Define float animation first
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

// Add CSS variables at the top
const theme = {
    primary: '#2A5C82', // Deep professional blue
    secondary: '#FF7E67', // Warm accent
    text: '#2D3436',
    lightText: '#636E72',
    background: '#F9FBFC',
    success: '#27AE60',
    error: '#EB5757',
    font: `'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif`
};

const WaitlistPage = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [interest, setInterest] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(() => {
        return sessionStorage.getItem('waitlistSubmitted') === 'true';
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isSubmitted) {
            sessionStorage.setItem('waitlistSubmitted', 'true');
        }
    }, [isSubmitted]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setError('Please enter a valid email address');
            return;
        }
        if (!interest) {
            setError('Please select your interest');
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch('https://formspree.io/f/xwpvawyl', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    name: name || 'Not provided',
                    referralCode: referralCode || 'None',
                    interest,
                    timestamp: new Date().toISOString(),
                    source: 'Waitlist Page'
                }),
            });
            if (response.ok) {
                setIsSubmitted(true);
            } else {
                setError('Submission failed. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MainWrapper>
            <StorkBox>
                <ScrollContainer>
                    <LogoWrapper>
                        <FloatingLogo src={logo} />
                        <CloudBackground />
                    </LogoWrapper>
                    
                    {isSubmitted ? (
                        <SuccessContent>
                            <ScrollContainer>
                                <h2>You're In! 🎉</h2>
                                <Subheading>
                                    Thanks for joining us in revolutionizing gifting for new parents<br />
                                    We'll be in touch soon with exclusive updates.
                                </Subheading>
                                <TestimonialBox>
                                    <QuoteIcon>"</QuoteIcon>
                                    Joining early gave me access to gifting the Stork Box, and oh my god it is gorgeous.
                                    <TestimonialAuthor>- Emily R., Future Baby Shower MVP</TestimonialAuthor>
                                </TestimonialBox>
                                <SharePrompt>
                                    <strong>Love Stork?</strong> Share your unique code and move up the list:
                                    <ReferralCode>STORKFAN-{email.slice(2,5).toUpperCase()}</ReferralCode>
                                </SharePrompt>
                            </ScrollContainer>
                        </SuccessContent>
                    ) : (
                        <FormContent>
                            <MainHeader>
                                <h1>Gift the Perfect Welcome</h1>
                            </MainHeader>

                            <HeroSection>
                                <BoxImageContainer>
                                    <BoxImage src={pilotbox} alt="Stork Box Preview" />
                                </BoxImageContainer>
                                <HeroText>
                                    The perfect gift for new parents,<br />
                                    <span className="highlight">delivered with care</span>
                                </HeroText>
                            </HeroSection>

                            <ValueGrid>
                                <ValueCard>
                                    <div className="icon">💝</div>
                                    <h3>Stress-Free Gifting</h3>
                                    <p>We handle selection, wrapping, and delivery</p>
                                </ValueCard>
                                <ValueCard className="highlighted">
                                    <div className="icon">📆</div>
                                    <h3>Timed Delivery</h3>
                                    <p>Arrives right when needed most</p>
                                </ValueCard>
                                <ValueCard>
                                    <div className="icon">🎀</div>
                                    <h3>Beautiful Unboxing</h3>
                                    <p>Instagram-worthy presentation</p>
                                </ValueCard>
                            </ValueGrid>

                            <TestimonialCarousel>
                                <Testimonial>
                                    "The most thoughtful gift we received!"
                                    <Author>- New Mom, Chicago</Author>
                                </Testimonial>
                                <Testimonial>
                                    "Saved me hours of shopping!"
                                    <Author>- Busy Godmother</Author>
                                </Testimonial>
                            </TestimonialCarousel>

                            <ProductPreviewSection>
                                <h2>Your Future Stork Box Includes</h2>
                                <Subheading>Reserve your spot risk-free - no payment required</Subheading>
                                
                                <GiftPreview>
                                    <PreviewImage src={pilotbox} alt="Stork Box Preview" />
                                    <PreviewDetails>
                                        <h3>Curated Essentials for New Parents:</h3>
                                        <PreviewList>
                                            <PreviewItem>🍼 BPA-free baby bottle set</PreviewItem>
                                            <PreviewItem>👕 Organic cute cotton onesies </PreviewItem>
                                            <PreviewItem>🧸 Hypoallergenic plush toy</PreviewItem>
                                            <PreviewItem>🧴 Gentle, natural baby lotion</PreviewItem>
                                            <PreviewItem>✨ Plus more surprise essentials from new brands!</PreviewItem>
                                        </PreviewList>
                                    </PreviewDetails>
                                </GiftPreview>

                                <PricingNote>
                                    <div>Special Launch Price: <strong>$50</strong> <s>$65</s></div>
                                    <small>You'll only pay when we launch - no charges today</small>
                                </PricingNote>

                                <Guarantee>
                                    💖 100% Happiness Guarantee - Love it or your money back
                                </Guarantee>
                            </ProductPreviewSection>

                            <CTASection>
                                <h2>Join the Stork Launch Waitlist!</h2>
                                <p className="subtext">
                                    Join 500+ thoughtful friends & family members
                                </p>
                                
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label htmlFor="email">Email *</Label>
                                        <StyledInput 
                                            id="email"
                                            type="email" 
                                            placeholder="Your Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            disabled={isLoading}
                                        />
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        <Label htmlFor="name">Name (optional)</Label>
                                        <StyledInput 
                                            id="name"
                                            type="text" 
                                            placeholder="How should we call you?"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        <Label htmlFor="referral">Referral Code (optional)</Label>
                                        <StyledInput 
                                            id="referral"
                                            type="text" 
                                            placeholder="Friend's code?"
                                            value={referralCode}
                                            onChange={(e) => setReferralCode(e.target.value)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>What is your primary interest in the Stork Box?</Label>
                                        <RadioGroup>
                                            <RadioLabel>
                                                <input
                                                    type="radio"
                                                    name="interest"
                                                    value="own kid"
                                                    checked={interest === 'own kid'}
                                                    onChange={(e) => setInterest(e.target.value)}
                                                    required
                                                />
                                                Purchasing for my own child
                                            </RadioLabel>
                                            <RadioLabel>
                                                <input
                                                    type="radio"
                                                    name="interest"
                                                    value="gifting"
                                                    checked={interest === 'gifting'}
                                                    onChange={(e) => setInterest(e.target.value)}
                                                    required
                                                />
                                                Gifting to someone special
                                            </RadioLabel>
                                        </RadioGroup>
                                    </FormGroup>
                                    
                                    <CTAButton type="submit" disabled={isLoading}>
                                        {isLoading ? 'Saving Your Spot...' : 'Join Waitlist!'}
                                    </CTAButton>
                                    
                                    <InfoBox>
                                        🔒 We respect your privacy. No spam, ever.
                                    </InfoBox>
                                </Form>
                            </CTASection>

                        </FormContent>
                    )}
                </ScrollContainer>
            </StorkBox>
        </MainWrapper>
    );
};

// Then define styled components that use it
const LogoWrapper = styled.div`
    margin-bottom: 2rem;
    animation: ${float} 3s ease-in-out infinite;
`;

const Logo = styled.img`
    width: 100px;
`;

const FormContent = styled.div`
    position: relative;
    padding-bottom: 2rem;
`;

const Subheading = styled.p`
    color: #636e72;
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 2rem;
`;

const FormGroup = styled.div`
    width: 100%;
    text-align: left;
    margin-bottom: 1.5rem;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.5rem;
    color: #2d3436;
    font-weight: 600;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 14px 20px;
    border: 2px solid #E0E7EC;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: ${theme.text};

    &:focus {
        border-color: ${theme.primary};
        box-shadow: 0 0 0 3px rgba(42, 92, 130, 0.1);
        outline: none;
    }

    &::placeholder {
        color: #A0AEC0;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 0.5rem;
    }
`;

const InfoBox = styled.div`
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #636e72;
    margin-top: 1rem;
`;

const CTAButton = styled.button`
    width: 100%;
    padding: 16px 24px;
    background: linear-gradient(135deg, ${theme.primary} 0%, ${theme.secondary} 100%);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 5px 15px rgba(42, 92, 130, 0.2);
    }

    &:active {
        transform: translateY(0);
    }

    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 0.75rem;
    }
`;

const TestimonialBox = styled.div`
    background: #f8f9fa;
    border-left: 3px solid #0984e3;
    padding: 1.5rem;
    border-radius: 8px;
    margin: 2rem 0;
    text-align: left;
    position: relative;
`;

const QuoteIcon = styled.span`
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 3rem;
    color: #0984e3;
    opacity: 0.2;
`;

const TestimonialAuthor = styled.p`
    margin-top: 1rem;
    font-weight: 600;
    color: #2d3436;
`;

const SharePrompt = styled.div`
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 10px;
    margin-top: 2rem;
    border: 2px dashed #dfe6e9;
`;

const ReferralCode = styled.div`
    font-family: 'Courier New', monospace;
    font-size: 1.2rem;
    color: #0984e3;
    background: #ffffff;
    padding: 0.8rem 1.2rem;
    border-radius: 6px;
    margin-top: 1rem;
    display: inline-block;
    border: 2px solid #dfe6e9;
`;

// New dedicated styled components
const MainWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: ${theme.background};
    padding: 2rem;
    font-family: ${theme.font};

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const StorkBox = styled.div`
    background: white;
    border-radius: 24px;
    padding: 3rem;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 880px;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(42, 92, 130, 0.1);

    @media (max-width: 768px) {
        padding: 2rem;
    }
`;

const FloatingLogo = styled(Logo)`
    width: 120px;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
    position: relative;
    z-index: 1;
`;

const CloudBackground = styled.div`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 200%;
    height: 200px;
    background: url('/cloud-pattern.png') repeat-x;
    opacity: 0.1;
    pointer-events: none;
`;

const MainHeader = styled.div`
    margin-bottom: 2rem;
    
    h1 {
        font-size: 2.8rem;
        color: ${theme.text};
        margin: 0 0 1.5rem;
        line-height: 1.2;
        font-weight: 700;
        
        @media (max-width: 768px) {
            font-size: 2.2rem;
        }
    }
`;

const EmojiStrip = styled.div`
    font-size: 2rem;
    letter-spacing: 0.5rem;
    opacity: 0.8;
    margin: 1rem 0;
`;

const HeroSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    text-align: center;
    background: linear-gradient(135deg, rgba(42,92,130,0.03) 0%, rgba(255,126,103,0.03) 100%);
    border-radius: 16px;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const BoxImageContainer = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const BoxImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
`;

const BoxCaption = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    font-weight: 600;
    color: #2d3436;
    font-size: 1.1rem;
`;

const HeroText = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent, rgba(0,0,0,0.7));
    padding: 1.5rem;
    color: white;
    font-size: 1.4rem;
    text-shadow: 0 2px 4px rgba(0,0,0,0.3);
    
    .highlight {
        color: #ffd700;
        font-weight: 600;
    }
`;

const ValueGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin: 2rem 0;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ValueCard = styled.div`
    padding: 2rem;
    background: ${props => props.highlighted ? theme.primary : 'white'};
    border-radius: 12px;
    color: ${props => props.highlighted ? 'white' : theme.text};
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid ${props => props.highlighted ? theme.primary : '#E0E7EC'};

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    }

    .icon {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
        display: inline-block;
        background: ${props => props.highlighted ? 'rgba(255,255,255,0.1)' : 'rgba(42,92,130,0.05)'};
        padding: 1rem;
        border-radius: 8px;
    }

    h3 {
        font-size: 1.3rem;
        margin: 0 0 1rem;
        font-weight: 600;
    }

    p {
        color: ${props => props.highlighted ? 'rgba(255,255,255,0.9)' : theme.lightText};
        line-height: 1.6;
    }
`;

const TestimonialCarousel = styled.div`
    margin: 2rem 0;
    background: #0984e3;
    padding: 2rem;
    border-radius: 15px;
    color: white;
`;

const Testimonial = styled.blockquote`
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 0;
    font-style: italic;
`;

const Author = styled.div`
    margin-top: 1rem;
    font-weight: 600;
    opacity: 0.9;
`;

const CTASection = styled.div`
    margin-top: 2rem;
    padding: 2rem;
    background: rgba(9, 132, 227, 0.1);
    border-radius: 15px;

    h2 {
        font-size: 1.8rem;
        color: #2d3436;
        margin: 0 0 1rem;
    }

    .subtext {
        color: #636e72;
        margin-bottom: 2rem;
    }
`;

const SecurityBadge = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.2rem;
    background: white;
    border-radius: 25px;
    font-size: 0.9rem;
    color: #0984e3;
    margin-top: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const FeatureShowcase = styled.div`
    margin: 4rem 0;
`;

const SectionTitle = styled.h2`
    font-size: 2rem;
    color: #2d3436;
    margin-bottom: 2rem;
`;

const FeatureItem = styled.div`
    display: flex;
    flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'};
    gap: 2rem;
    margin: 3rem 0;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const FeatureImage = styled.img`
    width: 50%;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const FeatureContent = styled.div`
    flex: 1;
    text-align: left;
    
    h3 {
        font-size: 1.8rem;
        color: #2d3436;
        margin: 1rem 0;
    }

    ul {
        list-style: none;
        padding: 0;
        
        li {
            margin: 1rem 0;
            padding: 1rem;
            background: #f8f9fa;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    }
`;

const FeatureTag = styled.span`
    background: #0984e3;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    display: inline-block;
`;

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 4rem 0;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const StatItem = styled.div`
    background: rgba(9, 132, 227, 0.1);
    padding: 2rem;
    border-radius: 15px;
    text-align: center;
`;

const StatNumber = styled.div`
    font-size: 2.5rem;
    font-weight: 700;
    color: #0984e3;
    margin-bottom: 0.5rem;
`;

const StatLabel = styled.div`
    color: #636e72;
    font-size: 1.1rem;
`;

const ProductPreviewSection = styled.div`
    margin: 2rem 0;
    padding: 1.5rem;
    background: rgba(250, 250, 250, 0.95);
    backdrop-filter: none;
`;

const GiftPreview = styled.div`
    min-height: 300px;
    align-items: flex-start;
    margin: 1.5rem 0;
`;

const PreviewImage = styled.img`
    width: 300px;
    height: auto;
    max-width: 100%;
`;

const PreviewDetails = styled.div`
    flex: 1;
    text-align: left;

    h3 {
        color: #2d3436;
        margin-top: 0;
    }
`;

const PreviewList = styled.ul`
    columns: 1;
    @media (min-width: 768px) {
        columns: 2;
    }
`;

const PreviewItem = styled.li`
    break-inside: avoid;
    margin-bottom: 1rem;
    padding: 0.8rem;
`;

const PricingNote = styled.div`
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 10px;
    margin: 1.5rem 0;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    
    strong {
        color: #0984e3;
        font-size: 1.4em;
    }
    
    s {
        color: #636e72;
        margin-left: 0.5rem;
    }
    
    small {
        display: block;
        margin-top: 0.5rem;
        color: #636e72;
        font-size: 0.9em;
    }
`;

const Guarantee = styled.div`
    color: #0984e3;
    font-weight: 500;
    padding: 1rem;
    border: 2px solid #0984e3;
    border-radius: 8px;
    margin-top: 1.5rem;
`;

const ScrollContainer = styled.div`
    overflow-y: auto;
    height: 100vh;
    padding: 0 2rem 2rem;
    
    /* Hide scrollbar for Chrome/Safari/Edge */
    &::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for Firefox */
    scrollbar-width: none;
    
    @media (max-width: 768px) {
        height: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 1rem 1rem;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem;

    @media (max-width: 768px) {
        padding: 0.5rem;
    }
`;

const SuccessContent = styled.div`
    position: relative;
    padding-bottom: 2rem;
`;

// Styled components for radio buttons
const RadioGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: ${theme.text};
`;

export default WaitlistPage;
