import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Hero from './components/Hero';
import Mission from './components/MissionSection';
import FeatureShowcase from './components/Showcase';
import GiftingFeature from './components/GiftingFeature';
import Reviews from './components/Reviews';
import Footer from './components/Footer';
import AIProfileFeature from './components/AIProfileFeature';
import ContactSection from './components/ContactSection';
import StorkGifting from './components/StorkGifting';
import Success from './components/SuccessPage';
import FAQSection from './components/FAQSection';
import CommunitySection from './components/CommunitySection';
import HowStorkHelps from './components/HowStorkHelps';
import StripeOnboardingComplete from './components/StripeOnboardingComplete';
import StorkBoxLitePage from './components/StorkBoxLitePage';
import WaitlistPage from './components/WaitlistPage';

function App() {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const handleScroll = useCallback(() => {
    setIsHeaderFixed(window.scrollY >= 100);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup scroll listener on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const Section = ({ id, children }) => (
    <section
      id={id}
      className="py-24 px-5 text-center min-h-screen"
      style={{ paddingTop: isHeaderFixed ? '150px' : '100px' }}
    >
      {children}
    </section>
  );

  return (
    <Router>
      <div className="font-sans bg-[#fbf8f3]">
        <Header className={`main-header ${isHeaderFixed ? 'fixed-header' : ''}`} />
        
        <Routes>
          {/* ---------------- HOME ROUTE ---------------- */}
          <Route
            path="/"
            element={
              <>
                <Section id="hero">
                  <Hero />
                </Section>
                <Section id="How">
                  <HowStorkHelps />
                </Section>
                <Section id="how-it-works">
                  <FeatureShowcase />
                </Section>
                <Section id="gifting">
                  <GiftingFeature />
                </Section>
                <Section id="ai-profile">
                  <AIProfileFeature />
                </Section>
                <Section id="FAQ">
                  <FAQSection />
                </Section>
                <Section id="mission">
                  <Mission />
                </Section>
                <Section id="reviews">
                  <Reviews />
                </Section>
                <Section id="community">
                  <CommunitySection />
                </Section>
                <Section id="contact">
                  <ContactSection />
                </Section>
                <Footer />
              </>
            }
          />

          {/* ----------- DYNAMIC GIFTING ROUTE ----------- */}
          <Route
            path="/gifting/:giftId"
            element={
              <Section id="stork-gifting">
                <StorkGifting />
              </Section>
            }
          />

          {/* ----------- STORK BOX LITE ROUTE (EXCLUSIVE TO AD) ----------- */}
          <Route
            path="/stork-box-lite"
            element={
              <Section id="stork-box-lite">
                <StorkBoxLitePage />
              </Section>
            }
          />

          {/* ----------- WAITLIST ROUTE ----------- */}
          <Route
            path="/waitlist"
            element={
              <div className="main-app-container">
                <WaitlistPage className="waitlist-page-container" />
              </div>
            }
          />

          {/* ----------- SUCCESS PAGE ROUTE ----------- */}
          <Route
            path="/success"
            element={<Success />}
          />

          {/* ----------- STRIPE ONBOARDING COMPLETE ROUTE ----------- */}
          <Route
            path="/stripe-onboarding-complete"
            element={<StripeOnboardingComplete />}
          />

          {/* ----------- 404 / CATCH-ALL ROUTE ----------- */}
          <Route
            path="*"
            element={
              <div className="flex justify-center items-center h-screen">
                <h2 className="text-2xl font-bold">404: Page Not Found</h2>
                <a href="/" className="mt-4 text-blue-500 hover:underline">
                  Go to Homepage
                </a>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
