import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import babyimage from './babyimage.jpg';
import logo from './storklogo.png';

function Hero() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <HeroSection>
      <ContentWrapper
        as={motion.div}
        initial={false}
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
        variants={containerVariants}
      >
        <TextContent>
          <Title as={motion.h1} variants={itemVariants}>
            Stork, parenthood simplified
          </Title>
          <Subtitle as={motion.p} variants={itemVariants}>
            It takes a village, and at Stork, we get that. Let us be your new best friend.
          </Subtitle>
          <Button as={motion.button} variants={itemVariants} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            Download Now &rarr;
          </Button>
          <SubText as={motion.p} variants={itemVariants}>
            Stress-free parenthood starts here
          </SubText>
          <StatsWrapper as={motion.div} variants={itemVariants}>
            <Stat>
              <StatIcon>&rarr;</StatIcon>
              <StatText>
                <strong>1.5K+</strong> Happy parents
              </StatText>
            </Stat>
          </StatsWrapper>
        </TextContent>
        <ImageWrapper as={motion.div} variants={itemVariants}>
          <MainImageContainer>
            <StyledImage
              as={motion.img}
              src={babyimage}
              alt="Baby with Stork Box"
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
            <LogoContainer
              as={motion.div}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              <LogoImage src={logo} alt="Stork Logo" />
            </LogoContainer>
          </MainImageContainer>
        </ImageWrapper>
      </ContentWrapper>
    </HeroSection>
  );
}

// Styled components remain the same as in your original code
// ...



const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background: linear-gradient(135deg, #fef7f1, #f9e6da);

  @media (max-width: 768px) {
    padding: 60px 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  max-width: 1200px;
  width: 100%;
`;

const TextContent = styled.div`
  max-width: 550px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  color: #111;
  margin-bottom: 20px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.4rem;
  color: #444;
  margin-bottom: 30px;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  background: #ff6f3c;
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #ff8550;
    transform: translateY(-2px);
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SubText = styled.p`
  font-size: 1rem;
  color: #777;
  margin-top: 20px;
`;

const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
`;

const Stat = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  strong {
    font-size: 1.5rem;
    color: #111;
  }
`;

const StatIcon = styled.span`
  font-size: 1.2rem;
  color: #ff6f3c;
`;

const StatText = styled.div`
  font-size: 1rem;
  color: #555;
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
`;

const MainImageContainer = styled.div`
  position: relative;
  max-width: 500px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: -20px;
  left: 20px;
  max-width: 120px;

  @media (max-width: 768px) {
    bottom: -10px;
    left: 10px;
    max-width: 80px;
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: auto;
`;

export default Hero;
