import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import storkbox from './pilotbox.jpg'

const StorkBoxLitePage = () => {
    const [step, setStep] = useState(1);
    const [babyName, setBabyName] = useState('');
    const [age, setAge] = useState('');
    const [giftMessage, setGiftMessage] = useState('');
    const [shippingDetails, setShippingDetails] = useState({
        fullName: '',
        address: '',
        city: '',
        zipCode: ''
    });

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const handleCheckout = async () => {
        console.log("Proceeding to Stripe checkout");
    };

    const handleShippingChange = (e) => {
        setShippingDetails({
            ...shippingDetails,
            [e.target.id]: e.target.value
        });
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <StepContent>
                        <h2>Welcome to Stork</h2>
                        <Subheading>Surprise new parents with a personally curated Stork Box</Subheading>
                        <FeatureList>
                            <FeatureItem>
                                <FeatureIcon>🎁</FeatureIcon>
                                <FeatureText>
                                    <strong>Curated Excellence:</strong> Handpicked essentials for newborns
                                </FeatureText>
                            </FeatureItem>
                            <FeatureItem>
                                <FeatureIcon>❤️</FeatureIcon>
                                <FeatureText>
                                    <strong>Quality Assured:</strong> Adorable and safe premium items for baby's comfort
                                </FeatureText>
                            </FeatureItem>
                            <FeatureItem>
                                <FeatureIcon>⭐</FeatureIcon>
                                <FeatureText>
                                    <strong>Joy Delivered:</strong> Create a magical unboxing experience
                                </FeatureText>
                            </FeatureItem>
                        </FeatureList>
                        <TestimonialBox>
                            <QuoteIcon>"</QuoteIcon>
                            The Stork Box was the highlight of our baby shower! So thoughtful and practical. It's a must-have for new parents!
                            <TestimonialAuthor>- Sarah M., Delighted Mother</TestimonialAuthor>
                        </TestimonialBox>
                        <CTAButton onClick={nextStep}>Tell us about the baby!</CTAButton>
                    </StepContent>
                );
            case 2:
                return (
                    <StepContent>
                        <h2>Personalize Your Stork Box</h2>
                        <FormGroup>
                            <Label htmlFor="babyName">Baby's Name (if known)</Label>
                            <StyledInput 
                                id="babyName"
                                type="text" 
                                placeholder="E.g., Emma or 'Baby Smith'" 
                                value={babyName} 
                                onChange={(e) => setBabyName(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="age">Baby's Age</Label>
                            <StyledSelect 
                                id="age"
                                value={age} 
                                onChange={(e) => setAge(e.target.value)}
                            >
                                <option value="">Select Age Range</option>
                                <option value="newborn">Newborn (0-1 month)</option>
                                <option value="0-3 months">0-3 months</option>
                            </StyledSelect>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="giftMessage">Personal Message</Label>
                            <StyledTextArea 
                                id="giftMessage"
                                placeholder="Add your message here!" 
                                value={giftMessage} 
                                onChange={(e) => setGiftMessage(e.target.value)}
                            />
                        </FormGroup>
                        <InfoBox>
                            Not sure about the details? Don't worry leave it blank, we got you covered!
                        </InfoBox>
                        <ButtonGroup>
                            <SecondaryButton onClick={prevStep}>Back</SecondaryButton>
                            <CTAButton onClick={nextStep}>Continue to Preview</CTAButton>
                        </ButtonGroup>
                    </StepContent>
                );
            case 3:
                return (
                    <StepContent>
                        <h2>Leave it to us, we will make this a special moment!</h2>
                        <GiftPreview>
                            <PreviewImage src={storkbox} alt="Stork Box Preview" />
                            <PreviewDetails>
                                <h3>What's Inside Your Stork Box:</h3>
                                <PreviewList>
                                    <PreviewItem>Organic cotton onesie</PreviewItem>
                                    <PreviewItem>Cuddly, safe plush toy</PreviewItem>
                                    <PreviewItem>Gentle, natural baby lotion</PreviewItem>
                                    <PreviewItem>BPA-free baby bottle</PreviewItem>
                                    <PreviewItem>✨ ...and more delightful surprises!</PreviewItem>
                                </PreviewList>
                            </PreviewDetails>
                        </GiftPreview>
                        <PriceTag>Special Offer: $50 <OriginalPrice>$65</OriginalPrice></PriceTag>
                        <CTAButton onClick={nextStep}>Continue to Shipping</CTAButton>
                        <Guarantee>
                            100% Happiness Guarantee!
                        </Guarantee>
                    </StepContent>
                );
            case 4:
                return (
                    <StepContent>
                        <h2>Shipping Details</h2>
                        <FormGroup>
                            <Label htmlFor="fullName">Full Name</Label>
                            <StyledInput 
                                id="fullName"
                                type="text" 
                                placeholder="Enter recipient's full name"
                                value={shippingDetails.fullName}
                                onChange={handleShippingChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="address">Address</Label>
                            <StyledInput 
                                id="address"
                                type="text" 
                                placeholder="Enter shipping address"
                                value={shippingDetails.address}
                                onChange={handleShippingChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="city">City</Label>
                            <StyledInput 
                                id="city"
                                type="text" 
                                placeholder="Enter city"
                                value={shippingDetails.city}
                                onChange={handleShippingChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="zipCode">Zip Code</Label>
                            <StyledInput 
                                id="zipCode"
                                type="text" 
                                placeholder="Enter zip code"
                                value={shippingDetails.zipCode}
                                onChange={handleShippingChange}
                            />
                        </FormGroup>
                        <ButtonGroup>
                            <SecondaryButton onClick={prevStep}>Back</SecondaryButton>
                            <CTAButton onClick={handleCheckout}>Proceed to Payment</CTAButton>
                        </ButtonGroup>
                    </StepContent>
                );
            default:
                return null;
        }
    };

    return (
        <MainWrapper>
            <StorkBox>
                <LogoWrapper>
                    <Logo src="/stork-logo.png" alt="Stork Logo" />
                </LogoWrapper>
                <ProgressIndicator>
                    {[1, 2, 3, 4].map(num => (
                        <ProgressDot key={num} active={step >= num} />
                    ))}
                </ProgressIndicator>
                <AnimatePresence mode="wait">
                    <div
                        key={step}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.4 }}
                    >
                        {renderStep()}
                    </div>
                </AnimatePresence>
            </StorkBox>
        </MainWrapper>
    );
};

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const MainWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #F5F7FA 0%, #C3CFE2 100%);
    padding: 2rem;
    font-family: 'Nunito', sans-serif;
`;

const StorkBox = styled.div`
    background: #FFFFFF;
    border-radius: 20px;
    padding: 3rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
    text-align: center;
    position: relative;
    overflow: hidden;
`;

const LogoWrapper = styled.div`
    margin-bottom: 2rem;
`;

const Logo = styled.img`
    width: 120px;
    animation: ${float} 3s ease-in-out infinite;
`;

const ProgressIndicator = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
`;

const ProgressDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${props => props.active ? '#4A90E2' : '#E0E0E0'};
    margin: 0 5px;
    transition: background 0.3s ease;
`;

const StepContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    h2 {
        color: #4A90E2;
        font-size: 2.2rem;
        margin-bottom: 0.5rem;
    }
`;

const Subheading = styled.p`
    color: #4A4A4A;
    font-size: 1.2rem;
    margin-top: 0;
`;

const FeatureList = styled.ul`
    list-style-type: none;
    padding: 0;
    text-align: left;
`;

const FeatureItem = styled.li`
    margin-bottom: 1rem;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 15px;
`;

const FeatureIcon = styled.span`
    font-size: 1.5rem;
`;

const FeatureText = styled.span`
    flex: 1;
`;

const TestimonialBox = styled.div`
    background: #F5F7FA;
    border-left: 4px solid #4A90E2;
    padding: 1.5rem;
    font-style: italic;
    border-radius: 0 10px 10px 0;
    position: relative;
    text-align: left;
`;

const QuoteIcon = styled.span`
    font-size: 3rem;
    color: #4A90E2;
    position: absolute;
    top: -10px;
    left: 10px;
    opacity: 0.2;
`;

const TestimonialAuthor = styled.p`
    text-align: right;
    margin-top: 1rem;
    font-weight: bold;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
`;

const Label = styled.label`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 12px;
    border: 2px solid #E0E0E0;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;

    &:focus {
        outline: none;
        border-color: #4A90E2;
    }
`;

const StyledSelect = styled.select`
    width: 100%;
    padding: 12px;
    border: 2px solid #E0E0E0;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;

    &:focus {
        outline: none;
        border-color: #4A90E2;
    }
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    padding: 12px;
    border: 2px solid #E0E0E0;
    border-radius: 8px;
    height: 100px;
    font-size: 1rem;
    transition: border-color 0.3s ease;

    &:focus {
        outline: none;
        border-color: #4A90E2;
    }
`;

const InfoBox = styled.div`
    background: #E8F4FD;
    padding: 1rem;
    font-size: 0.9rem;
    color: #4A90E2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
`;

const CTAButton = styled.button`
    background: #4A90E2;
    color: #FFFFFF;
    padding: 14px 28px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    transition: all 0.3s ease;

    &:hover {
        background: #3A7BC8;
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(74, 144, 226, 0.3);
    }
`;

const SecondaryButton = styled(CTAButton)`
    background: #FFFFFF;
    color: #4A90E2;
    border: 2px solid #4A90E2;

    &:hover {
        background: #F5F7FA;
    }
`;

const GiftPreview = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: #F5F7FA;
    padding: 1.5rem;
    border-radius: 10px;
`;


const PreviewImage = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
`;

const PreviewDetails = styled.div`
    text-align: left;

    h3 {
        margin-top: 0;
        color: #4A90E2;
    }
`;

const PreviewList = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const PreviewItem = styled.li`
    margin-bottom: 0.5rem;
`;

const PriceTag = styled.div`
    font-size: 1.8rem;
    font-weight: bold;
    color: #4A90E2;
    margin: 1.5rem 0;
`;

const OriginalPrice = styled.span`
    text-decoration: line-through;
    color: #999;
    font-size: 1.4rem;
    margin-left: 10px;
`;

const Guarantee = styled.div`
    font-size: 1rem;
    color: #4A4A4A;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; 
`;
export default StorkBoxLitePage;
