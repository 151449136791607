import React, { useState } from 'react';
import styled from 'styled-components';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Nav>
      <Logo>Stork</Logo>
      <Hamburger onClick={toggleMenu}>
        {menuOpen ? <CloseIcon>&times;</CloseIcon> : <HamburgerIcon>&#9776;</HamburgerIcon>}
      </Hamburger>
      <NavLinks $isOpen={menuOpen}>
        <NavLink href="/#how-it-works" onClick={toggleMenu}>Products</NavLink>
        <NavLink href="/#FAQ" onClick={toggleMenu}>FAQs</NavLink>
        <NavLink href="/#mission" onClick={toggleMenu}>Our Mission</NavLink>
        <NavLink href="/#reviews" onClick={toggleMenu}>Reviews</NavLink>
        <NavLink href="/#contact" onClick={toggleMenu}>Contact</NavLink>
        <ActionButton>Coming Soon On The App Store</ActionButton>
      </NavLinks>
    </Nav>
  );
}

// Styled Components
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const HamburgerIcon = styled.span`
  font-size: 1.8rem;
`;

const CloseIcon = styled.span`
  font-size: 1.8rem;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  @media (max-width: 768px) {
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')}; /* Transient prop ($isOpen) */
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: white;
    justify-content: center;
    align-items: center;
    z-index: 999;
    gap: 20px;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    color: #e49f6b;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    text-align: center;
  }
`;

const ActionButton = styled.button`
  background: #dd4912;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #e49f6b;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
  }
`;

export default Header;
